#header {
  padding: 0 0 0 0;
}

#banner {
    // background: url(phc_logo_outline.png), url(banner.mov);
    background: url(phc_logo_outline.png), url(grain_bg.png);
    background-repeat: no-repeat, no-repeat;

    background-position: top center, center center;
    background-size: auto calc(100% - 7em), cover;
}

#bannerlogolink {
  // prevent the logo from mucking up the formatting of the header
  padding: 0 0 0 0 !important;
}

#bannerlogolink > img {
  height: 80px;
}

figure > img {
    max-width: 100%;
    height: auto;
}

@include breakpoint('<=narrower') {
  figure.floatright {
    max-width: 100%;
    max-height: 100%;
  }

  figure.floatleft {
    max-width: 100%;
    max-height: 100%;
  }
}

@include breakpoint('>narrower') {
  #header > h1 {
    // hide the title bar for wide screens
    display: none;
  }

  figure.floatright {
    max-width: 30%;
    // padding: 2em;
    height: auto;
    float: right;
  }

  figure.floatleft {
    max-width: 30%;
    padding: 2em;
    height: auto;
    float: left;
  }

}
